<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardBody>
            <CTabs
              variant="pills"
              vertical
              :active-tab="userObj.pin_setup === 'no' ? 2 : 0"
            >
              <CTab>
                <template slot="title">
                  <CIcon name="cil-user" size="xl" />
                  <span class="h5 ml-1">General Information</span>
                </template>
                <CCol sm="12" md="12">
                  <CCard>
                    <CCardHeader>
                      <strong class="h5">Personal Details</strong>
                      <p>
                        Edit your personal information and change your display
                        picture.
                      </p>
                    </CCardHeader>
                    <CCardBody>
                      <CCol md="12" sm="12">
                        <CCard>
                          <CCardBody>
                            <CForm>
                              <CRow
                                align-vertical="center"
                                class="flex flex-wrap justify-content-between"
                                align-horizontal="center"
                              >
                                <CCol
                                  sm="12"
                                  md="12"
                                  lg="6"
                                  class="align-items-center"
                                >
                                  <CRow
                                    class="flex align-items-center justify-content-around justify-content-lg-start ml-lg-1"
                                  >
                                    <CImg
                                      :src="userObj.image"
                                      :style="
                                        imageLoader === true
                                          ? 'opacity: .4'
                                          : ''
                                      "
                                      alt="Image Description"
                                      class="h-25 w-25 rounded-circle"
                                    />
                                    <input
                                      type="file"
                                      class="upload"
                                      ref="fileInput"
                                      accept="image/*"
                                      style="display: none;"
                                      @change="onFilePicked"
                                    />
                                    <div class="ml-lg-2">
                                      <p class="mb-0">
                                        <strong>
                                          {{ userObj.name }}
                                        </strong>
                                      </p>
                                      <p class="mt-0">
                                        <CIcon
                                          name="cil-badge"
                                          size="xl"
                                          class="rounded-pill text-light"
                                          :class="
                                            getBadge(
                                              userObj.customer_level_name
                                            )
                                          "
                                        />
                                        {{ userObj.customer_level_name }}
                                      </p>
                                    </div>
                                  </CRow>
                                </CCol>

                                <CCol
                                  sm="12"
                                  md="6"
                                  lg="3"
                                  class="text-center mt-2 mt-lg-0"
                                >
                                  <CButton
                                    block
                                    variant="outline"
                                    color="info"
                                    @click="onPickFile"
                                    size="sm"
                                    class="align-items-center float-right"
                                  >
                                    <CIcon
                                      name="cil-camera"
                                      class="primary"
                                      size="xl"
                                    />
                                    Update Image
                                  </CButton>
                                </CCol>
                              </CRow>
                            </CForm>
                          </CCardBody>
                          <CCardBody>
                            <CForm
                              @submit.prevent="updateUser"
                              class="font-weight-bold h6"
                            >
                              <CInput
                                label="Full Name"
                                :value="user.name"
                                horizontal
                                plaintext
                                class=""
                              />
                              <CInput
                                description="Let us know your full name."
                                label="First Name"
                                horizontal
                                autocomplete="name"
                                v-model="user.first_name"
                              />

                              <CInput
                                description="Let us know your full name."
                                label="Last Name"
                                horizontal
                                autocomplete="name"
                                v-model="user.last_name"
                              />
                              <CInput
                                label="Email input"
                                description="This is your primary email address. Note: This email will be used to send notifications"
                                placeholder="Enter your email"
                                type="email"
                                horizontal
                                autocomplete="email"
                                v-model="user.email"
                                plaintext
                              />
                              <CInput
                                label="Phone Number"
                                description="This is your primary mobile number in which you registered with"
                                placeholder="Enter your email"
                                type="text"
                                horizontal
                                plaintext
                                autocomplete="email"
                                v-model="user.phone_number"
                              />
                              <CButton
                                color="primary"
                                class="px-4 float-right"
                                type="submit"
                                :disabled="loading"
                                size="lg"
                              >
                                <span v-if="loading"
                                  ><loader-icon
                                    size="1.5x"
                                    class="custom-class"
                                  ></loader-icon
                                  >Please Wait</span
                                >
                                <span v-else>Update</span>
                              </CButton>
                            </CForm>
                          </CCardBody>
                        </CCard>
                      </CCol>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CTab>

              <CTab>
                <template slot="title">
                  <CIcon name="cil-lock-locked" size="xl" />
                  <span class="h5 ml-1">Change Password</span>
                </template>
                <CCol sm="12" md="12">
                  <CCard>
                    <CCardHeader>
                      <strong class="h5">Change Password</strong>
                      <p>
                        Password must be at least 8 character and contain
                        symbols.
                      </p>
                    </CCardHeader>
                    <CCardBody>
                      <CCard>
                        <CCardBody>
                          <!-- Bootstrap Vue has some problems with Inline forms that's why we use some standard bootstrap classes -->
                          <CForm @submit.prevent="updatePassword">
                            <CInput
                              placeholder="Old Password"
                              type="password"
                              v-model="oldPassword"
                            >
                            </CInput>
                            <CInput
                              placeholder="New Password"
                              type="password"
                              v-model="password"
                            >
                            </CInput>
                            <CInput
                              placeholder="Confirm New Password"
                              type="password"
                              v-model="passwordConfirmation"
                            >
                            </CInput>
                            <CButton
                              type="submit"
                              size="md"
                              color="primary"
                              :disabled="passwordLoader || !formIsValid"
                            >
                              <span v-if="passwordLoader">
                                <loader-icon class="custom-class"></loader-icon
                                >Please Wait
                              </span>
                              <span v-else>
                                <CIcon
                                  name="cil-check-circle"
                                  class="mr-1"
                                />Update Password
                              </span>
                            </CButton>
                          </CForm>
                        </CCardBody>
                      </CCard>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CTab>
              <CTab>
                <template slot="title">
                  <CIcon name="cil-lock-locked" size="xl" />
                  <span class="h5 ml-1">{{
                    userObj.pin_setup === "no" ? "Setup Pin" : "Change Pin"
                  }}</span>
                </template>
                <CCol sm="12" md="12">
                  <CCard>
                    <CCardHeader>
                      <strong class="h5">{{
                        userObj.pin_setup === "no" ? "Setup Pin" : "Change Pin"
                      }}</strong>
                      <p>
                        Password must be 4 digits.
                      </p>
                    </CCardHeader>
                    <CCardBody>
                      <CCard>
                        <CCardBody>
                          <!-- Bootstrap Vue has some problems with Inline forms that's why we use some standard bootstrap classes -->
                          <CForm @submit.prevent="updatePin">
                            <CInput
                              placeholder="Old Pin"
                              type="password"
                              v-model="pinData.old_pin"
                              v-if="userObj.pin_setup === 'yes'"
                            >
                            </CInput>
                            <CInput
                              placeholder="New Pin"
                              type="password"
                              v-model="pinData.pin"
                            >
                            </CInput>
                            <CInput
                              placeholder="Confirm New Pin"
                              type="password"
                              v-model="pinData.pin_confirmation"
                            >
                            </CInput>
                            <CButton
                              type="submit"
                              size="md"
                              color="primary"
                              :disabled="pinLoader || !pinFormIsValid"
                            >
                              <span v-if="pinLoader">
                                <loader-icon class="custom-class"></loader-icon
                                >Please Wait
                              </span>
                              <span v-else>
                                <CIcon
                                  name="cil-check-circle"
                                  class="mr-1"
                                />Update Pin
                              </span>
                            </CButton>
                          </CForm>
                        </CCardBody>
                      </CCard>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../services/api.service";
import Swal from "sweetalert2";
export default {
  name: "Tabs",
  data() {
    return {
      imageLoader: false,
      passwordLoader: false,
      pinLoader: false,

      oldPassword: "",
      password: "",
      passwordConfirmation: "",
      pinData: {
        old_pin: "",
        pin: "",
        pin_confirmation: "",
      },
      user: {},
      tabs: ["Calculator", "Shopping cart", "Charts"],
      activeTab: 1,
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label: "col-3", input: "col-9" },
      options: ["Option 1", "Option 2", "Option 3"],
      selectOptions: [
        "Option 1",
        "Option 2",
        "Option 3",
        {
          value: "some value",
          label: "Selected option",
        },
      ],
      selectedOption: "some value",

      formCollapsed: true,
      checkboxNames: [
        "Checkboxes",
        "Inline Checkboxes",
        "Checkboxes - custom",
        "Inline Checkboxes - custom",
      ],
      radioNames: [
        "Radios",
        "Inline Radios",
        "Radios - custom",
        "Inline Radios - custom",
      ],
    };
  },
  computed: {
    ...mapGetters(["userObj"]),
    loading() {
      return this.$store.getters.loading;
    },
    formIsValid() {
      return (
        this.password !== "" &&
        this.oldPassword !== "" &&
        this.passwordConfirmation !== ""
      );
    },
    pinFormIsValid() {
      return this.pinData.pin !== "" && this.pinData.pin_confirmation !== "";
    },
  },
  methods: {
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    updateUser() {
      let data = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        phone_number: this.user.phone_number,
      };
      this.$store.dispatch("updateUser", data);
    },
    async updatePassword() {
      this.passwordLoader = true;
      let data = {
        old_password: this.oldPassword,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      };
      console.log(data);
      try {
        let response = await ApiService.post("/user/update-password", data);
        console.log(response);
        this.passwordLoader = false;
        Swal.fire({
          title: "Success",
          icon: "success",
          text: response.data.response_message,
          timer: 3000,
          confirmButtonText: "Close",
          showConfirmButton: false,
        });

        this.oldPassword = "";
        this.password = "";
        this.passwordConfirmation = "";
      } catch (error) {
        console.log(error);
      }
    },
    async updatePin() {
      this.pinLoader = true;
      console.log(this.pinData);
      try {
        if (this.userObj.pin_setup === "no") {
          let response = await ApiService.post("/user/setup-pin", this.pinData);
          console.log(response);
          this.pinLoader = false;

          Swal.fire({
            title: "Success",
            icon: "success",
            text: response.data.response_message,
            timer: 3000,
            confirmButtonText: "Close",
            showConfirmButton: false,
          });
          this.pinData.pin = "";
          this.pinData.pin_confirmation = "";
        } else {
          let response = await ApiService.post(
            "/user/update-pin",
            this.pinData
          );
          console.log(response);
          this.pinLoader = false;

          Swal.fire({
            title: "Success",
            icon: "success",
            text: response.data.response_message,
            timer: 3000,
            confirmButtonText: "Close",
            showConfirmButton: false,
          });
          this.pinData.old_pin = "";
          this.pinData.pin = "";
          this.pinData.pin_confirmation = "";
        }
      } catch (error) {
        console.log(error.response);
        this.pinLoader = false;
        this.pinData = {};
        Swal.fire({
          title: "Failed",
          icon: "error",
          text: error.response.data.response_message,
          timer: 3000,
          showConfirmButton: false,
          confirmButtonText: "Close",
        });
        this.pinData.pin = "";
        this.pinData.pin_confirmation = "";
      }
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    // Change previous profile photo to new photo
    // when new is chosen
    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file!");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.userObj.image = fileReader.result;
        // this.image = files[0]
        let data = {
          profile_image: this.userObj.image,
        };
        this.uploadPhoto(data);
      });
      fileReader.readAsDataURL(files[0]);
    },
    async uploadPhoto(data) {
      this.imageLoader = true;
      try {
        const res = await ApiService.post("/user/update-profile-image", data);
        this.imageLoader = false;
        console.log(res);
        Swal.fire({
          title: "Success",
          text: res.data.response_message,
          icon: "success",
          timer: 3000,
          confirmButtonText: "Close",
          showConfirmButton: false,
        });
        this.$store.commit("setUser", res.data.data);
        this.$router.push({ name: "Dashboard" });
      } catch (error) {
        this.imageLoader = false;
        console.log(error.response);
      }
    },
    getBadge(status) {
      switch (status) {
        case "Bronze":
          return "bg-warning";
        case "Silver":
          return "bg-secondary";
        case "Gold":
          return "bg-warning";
        default:
          "bg-info";
      }
    },
  },
  mounted() {
    this.user = this.userObj;
  },
};
</script>
<style scoped>
.avatar-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.helo {
  border: 2px solid red;
}
</style>
